import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { LayoutWhite } from "@layout"
import { HeroLegal2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import "@styles/legal.scss"

const Sweepstakes = () => {
  return (
    <LayoutWhite
      backgroundImage="animated"
      hero={
        <HeroLegal2022Q3 title="ATM.com&trade; “Streakstakes” Sweepstakes" />
      }
    >
      <SEO title="Sweepstakes" />
      <Container className="policy-container body-1">
        <h2 className="underline">OFFICIAL RULES</h2>
        <p>
          <strong>
            NO PURCHASE NECESSARY TO ENTER OR WIN. <br />A PURCHASE OR PAYMENT
            WILL NOT IMPROVE YOUR CHANCE OF WINNING.
          </strong>
        </p>
        <p>
          <strong>
            Receive an entry by simply answering questions, completing surveys,
            accepting a partner offer, or playing games (see Section 5 below).
          </strong>
        </p>
        <p>
          <u>1. Eligibility:</u> This ATM.comTM Streakstakes Sweepstakes
          ("Sweepstakes") is open only to legal residents of, and physically
          located within, the fifty (50) United States and District of Columbia
          and are of age eighteen (18) years or older at time of entry. If a
          potential winner is at least 18 years old but still considered a minor
          in his/her jurisdiction of residence, prize may be awarded in the name
          of his/her parent or legal guardian who will be responsible for
          fulfilling all requirements imposed on winner(s) set forth herein. All
          federal, state, and local laws and regulations apply. Void where
          prohibited by law. Void outside the USA, in Puerto Rico, in the US
          Virgin Islands, and in other United States territories and
          possessions. Employees of ATM.com, Inc. (the "Sponsor") and its
          respective affiliates, subsidiaries, successors, assigns, agents,
          representatives, officers, directors, shareholders, and employees, and
          any entity involved in the development, production, implementation,
          administration, judging or fulfillment of the Sweepstakes, including
          without limitation, the immediate family members of such individuals,
          are not eligible to participate. Winning Prizes is contingent upon
          fulfilling all requirements set forth herein.
        </p>
        <p>
          <u>2. Sponsorship:</u> The Sweepstakes sponsor is ATM.com, Inc,
          ("Sponsor"), located at 4600 Campus Drive, Suite 107, Newport Beach,
          CA 92660. Sponsor will conduct the Sweepstakes substantially as
          described in these Official Rules.
        </p>
        <p>
          <u>3. Agreement to Rules:</u> By entering this Sweepstakes, the
          entrant ("Entrant" or "You") agrees to abide by the Sponsor's Official
          Rules and decisions, which are fully and unconditionally binding in
          all respects. The Sponsor reserves the right to refuse, withdraw, or
          disqualify any entry at any time at the Sponsor's sole discretion. By
          entering this Sweepstakes, You represent and warrant that You are
          eligible to participate based on eligibility requirements explained in
          these Official Rules. You also agree to accept the decisions of the
          Sponsor as final and binding as it relates to the content of this
          Sweepstakes.
        </p>
        <p>
          <u>4. Sweepstakes Entry Periods:</u> The Sweepstakes is comprised of a
          series of weekly periods (each, a “Period”) beginning September 21,
          2024. Each Period begins on a Saturday at 12:00am Pacific Time and
          ends on the following Friday at 11:59pm Pacific Time. The Sweepstakes
          will be conducted on a weekly basis until terminated at the sole
          discretion of the Sponsor.
        </p>
        <p>
          <u>5. How to Enter:</u> Users will be automatically entered in the
          Sweepstakes for the Prizes by taking any of four actions on the
          ATM.com app during a Period - actions for which there is no fee or
          payment required. You will receive a single entry for: (1) every
          survey You submit, for which You receive a minimum payout of three
          cents ($0.03), and for which the payout reward is not reversed; (2)
          every game reward that is not reversed; (3) for every set of Daily
          Questions that you answer; or, (4) for every partner offer that is
          completed, as defined by the Sponsor in its sole discretion, and that
          is not reversed (each one, an "Action"). Sweepstakes participants will
          receive one (1) entry per day for each type of daily action for a
          maximum of four (4) entries per day.
        </p>
        <p>
          A “Streak” is defined as seven (7) consecutive days during which you
          complete an Action or Actions. Streaks begin at 12:00am Pacific Time
          on the first day of the Streak as recorded by the Sponsor and end at
          11:59pm Pacific Time seven days later. The last day of the Streak will
          be the Period in which the entries will be registered for the prize
          drawing.
        </p>
        <p>
          Bonus entries will be awarded based on the number of continuous
          unbroken Streak Periods that are recorded for each Entrant as follows:
        </p>
        <ul>
          <li>
            For one 7-day Streak, Entrants will receive an additional 5 entries
            for that Period’s drawing;
          </li>
          <li>
            For two 7-day Streaks, Entrants will receive an additional 10
            entries for that Period’s drawing;
          </li>
          <li>
            For three 7-day Streaks, Entrants will receive an additional 15
            entries for that Period’s drawing:
          </li>
          <li>
            For four 7-day Streaks, Entrants will receive an additional 20
            entries for that Period’s drawing:
          </li>
        </ul>
        <p>
          For Streaks in excess of four, additional entries will continue to
          accumulate and accrue to the Entrant at the same rate for as long as
          the Sweepstakes is active.
        </p>
        <p>
          Any Streaks that are recorded with a disruption of consecutive days
          taking Actions will result in Entrants needing to start a new Streak
          for further consideration.
        </p>

        <p>
          <u>6. Mail-In Entries:</u> You may also enter the Sweepstakes by
          sending an envelope via regular United States Postal Service first
          class mail to the Sponsor at the address shown in Section 2 above. The
          envelope should only contain a 3" x 5" card with your name, complete
          mailing address (no P.O. boxes), email address, and the mobile phone
          number associated with your ATM.com account. Mailed entries will be
          entered in to the Sweepstakes for the Period in which they were
          received in the Sponsor’s office. Handwritten mail-in entries must be
          legible, clear, and easy to read.
        </p>
        <p>
          <u>7. Limitations on Entries:</u> The number of entries You may submit
          during any Period is limited to four (4) per day and there is no limit
          to the number of Periods you may enter. Sponsor reserves the right, in
          its sole discretion, to disqualify entries from users who have been
          flagged for suspicious behavior.
        </p>
        <p>
          <u>8. Prizes:</u> Five (5) Prizes will be award for each Period. Each
          Prize will consist of $50.00 deposited into the winner's ATM account
          and distributed based on their account configuration.
        </p>
        <p>
          <u>9. Prize Winners:</u> The Winners, upon the awarding of their
          prize, are solely responsible for all expenses related to the prize,
          including without limitation, any and all federal, state, and local
          taxes, if applicable. The prizes are non-transferable and no prize
          substitutions are allowed. A person may only win one (1) prize during
          a single Period.
        </p>
        <p>
          <u>10. Odds:</u> The odds of winning will depend on the number of
          eligible entries received, according to the eligibility requirements
          of the Sweepstakes.
        </p>
        <p>
          <u>11. Random Drawing:</u> Winners will be selected in random drawings
          from among all eligible entries collected during each Period at
          Sponsor's principal place of business, taking place on or about every
          Tuesday at 11:00am Pacific Time until such time as the Sweepstakes is
          terminated by the Sponsor. The drawing will be conducted by Sponsor,
          whose decisions and interpretations on all matters relating to the
          Sweepstakes and these Official Rules are final and binding in all
          respects.
        </p>
        <p>
          <u>12. Selection and Notification of Winner:</u> Each selected Prize
          winner will be notified by email to the email address on record and/or
          in-app messaging. Sponsor shall have no liability for a winner's
          failure to receive notices due to winner's spam, junk e-mail or other
          security settings or for winners' provision of incorrect or otherwise
          non-functioning contact information. If any potential winner is found
          to be ineligible, has not complied with these Official Rules, cannot
          be verified, declines a prize for any reason, or is suspected of any
          fraudulent activity, such potential winner will be disqualified and at
          Sponsor's discretion, Sponsor may award the prize to an alternate
          potential winner selected from the remaining eligible entries. THE
          DECISIONS OF THE SPONSOR ARE FINAL AND BINDING.
        </p>
        <p>
          <u>13. Publicity:</u> Except where prohibited by law, by accepting the
          prize, the selected Monthly Prize Winners grant permission for the
          Sponsor to use their likeness, entry, and name for purposes of
          advertising and trade without further compensation unless prohibited
          by law.
        </p>
        <p>
          <u>14. Limitation of Liability:</u> By entering You agree to release
          and hold harmless ATM.com, Inc. and its subsidiaries, affiliates,
          advertising and promotion agencies, partners, representatives, agents,
          successors, assigns, employees, officers and directors from any
          liability, illness, injury, death, loss, litigation, claim or damage
          that may occur, directly or indirectly, whether caused by negligence
          or not, from (i) such entrant's participation in the sweepstakes
          and/or his/her acceptance, possession, use, or misuse of any prize or
          any portion thereof, (ii) technical failures of any kind, including
          but not limited to the malfunctioning of any computer, cable, network,
          hardware or software; (iii) the unavailability or inaccessibility of
          any transmissions or telephone or Internet service; (iv) unauthorized
          human intervention in any part of the entry process or the
          Sweepstakes; (v) electronic or human error which may occur in the
          administration of the Sweepstakes or the processing of entries.
        </p>
        <p>
          <u>15. Disputes:</u> By participating, entrants agree that: (a) any
          and all disputes, claims and causes of action arising out of, or
          connected with, this Sweepstakes or the Prize, shall be resolved
          individually, without resort to any form of class action, and
          exclusively by the appropriate federal, state or local court located
          in Orange County, California; (b) any and all claims, judgments and
          awards shall be limited to actual out-of-pocket costs incurred,
          including but not limited to costs associated with entering this
          Sweepstakes, but in no event attorneys' fees; and (c) to the extent
          allowed by applicable law, under no circumstances will Entrant be
          permitted to obtain awards for, and entrant hereby waives all rights
          to claim, punitive, incidental and/or consequential damages and/or any
          other damages, other than actual out-of-pocket expenses, and any and
          all rights to have damages multiplied or otherwise increased. SOME
          JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY
          FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO PORTIONS OF THE ABOVE MAY
          NOT APPLY TO YOU.
        </p>
        <p>
          <u>16. Privacy Policy:</u> Information submitted with an entry is
          subject to the Privacy Policy stated on the ATM.com website and in the
          ATM.com app. <Link to="/privacy-policy">Click here</Link> to read the
          Privacy Policy.
        </p>
        <p>
          <u>17. Winners List:</u> You may obtain a copy of the winners' names,
          where permitted by law, or a copy of these Official Rules, by sending
          your request, separate from mail-in entries, via mail with a stamped,
          self-addressed envelope to: ATM.com, Inc., 4600 Campus Drive, Suite
          107, Newport Beach, CA 92660.
        </p>
        <p>
          <u>18. Governing Law:</u> All disputes concerning the construction,
          validity, interpretation and enforceability of these Official Rules or
          the rights and obligations of entrants or Sponsor in connection with
          the Sweepstakes shall be governed by and interpreted in accordance
          with the laws of the State of California, without regard to its
          conflict of laws principles. The parties hereby consent to exclusive
          jurisdiction and venue of the state and federal courts located in
          Orange County, California, in any action relating to these Official
          Rules or the Sweepstakes.
        </p>
        <p>
          <u>19. General Terms and Conditions:</u> By entering this Sweepstakes,
          You accept and agree to be bound by the Official Rules and decisions
          of Sponsor, which will be Official and binding in all respects.
          Sponsor reserves the right, at its sole discretion, to refuse,
          disqualify or withdraw any entry at any time. Sponsor will not be
          responsible for any injury, damage or loss of any kind arising out of
          your participation in the Sweepstakes. In no event will Sponsor be
          liable for awarding more than five (5) prizes per week. By entering
          the Sweepstakes, each entrant gives his/her express permission to be
          contacted by Sponsor by telephone, email and/or postal mail for
          Sweepstakes purposes. The Sponsor is not responsible for any
          typographical or other error in the printing of this offer,
          administration of this Sweepstakes or in the announcement of winner
          and prize. In the event the Sponsor is prevented from continuing with
          this promotion, or the integrity and/or feasibility of the promotion
          as planned is undermined by any event including but not limited to
          fire, flood, epidemic, pandemic, earthquake, explosion, labor dispute
          or strike, act of God or public enemy, satellite or equipment failure,
          riot or civil disturbance, war (declared or undeclared), terrorist
          threat or activity, or any federal, state, or local government law,
          order, or regulation, order of any court or jurisdiction (each a
          "Force Majeure" event or occurrence), Sponsor shall have the right, in
          its sole discretion, to abbreviate, modify, suspend, cancel, or
          terminate the sweepstakes and award the prize from among all eligible
          entries received up to that date. All federal, state and local laws
          and regulations apply. All trademarks used herein are the property of
          their respective owners. The invalidity or unenforceability of any
          provision of these Official Rules shall not affect the validity or
          enforceability of any other provision. In the event that any provision
          is determined to be invalid or otherwise unenforceable or illegal,
          these Official Rules shall otherwise remain in effect and shall be
          construed in accordance with their terms as if the invalid or illegal
          provision were not contained herein. In the event there is a
          discrepancy or inconsistency between disclosures or other statements
          contained in any Contest-related materials or made by any
          representative of Sponsors, and the terms and conditions of these
          Official Rules, these Official Rules shall prevail, govern and
          control.
        </p>
      </Container>
    </LayoutWhite>
  )
}

export default Sweepstakes
